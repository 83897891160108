<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import FriendItemsList from '@/features/items/components/FriendItemsList'
import AddItem from '@/features/items/components/AddItem'

export default {
  components: {
    FriendItemsList,
    AddItem,
  },

  props: {
    eventId: { type: String, required: true },
  },

  setup(props) {
    const { isBusy: isLoading, execute: loadEvent } = useAsyncLoading({
      actionFactory: async () => {
        const secretSantaEvent = await store.dispatch('friends/fetchSecretSantaEvent', {
          eventId: props.eventId,
        })

        await Promise.all([
          store.dispatch('items/fetchFriendSecretSantaSuggestedItems', {
            userId: secretSantaEvent.receiverUserId,
            budgetAmount: secretSantaEvent.budgetAmount,
          }),
          store.dispatch('localCache/fetchUserDisplaySummaries', {
            userIds: [secretSantaEvent.receiverUserId],
          }),
        ])
      },
    })

    onMounted(loadEvent)

    return {
      isLoading,
    }
  },

  computed: {
    authUserId: () => store.getters['auth/authUserId'],

    secretSantaEvent() {
      return store.getters['friends/getSecretSantaEventById'](this.eventId)
    },
    budgetAmount() {
      return this.secretSantaEvent ? this.secretSantaEvent.budgetAmount : undefined
    },
    suggestedItems() {
      return this.secretSantaEvent ? store.getters['items/getFriendSecretSantaSuggestedItems'](this.secretSantaEvent.receiverUserId) : []
    },
    receiverUserDisplaySummary() {
      return store.getters['localCache/getUserDisplaySummaryById'](this.secretSantaEvent.receiverUserId)
    },
  },

  methods: {
    onSurpriseItemAdded() {
      store.dispatch('items/fetchFriendSecretSantaSuggestedItems', {
        userId: this.secretSantaEvent.receiverUserId,
        budgetAmount: this.budgetAmount,
      })
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoading && !!secretSantaEvent">
      <v-skeleton-loader v-if="!secretSantaEvent" boilerplate type="card-heading"></v-skeleton-loader>

      <template v-else>
        <v-card-title>{{ secretSantaEvent.eventName }}</v-card-title>
        <v-card-subtitle>{{ secretSantaEvent.eventDate.substr(0, 10) }}</v-card-subtitle>

        <v-card-text>
          <div v-if="receiverUserDisplaySummary">
            You are buying a gift for
            <strong class="primary--text">{{ receiverUserDisplaySummary.fullName }}</strong>
          </div>

          <div v-if="budgetAmount">
            The budget is
            <strong class="success--text">R {{ budgetAmount }}</strong>
          </div>
        </v-card-text>
      </template>
    </v-card>

    <div v-if="suggestedItems && suggestedItems.length > 0">
      <div class="pt-3"></div>

      <v-card>
        <v-card-title>
          <div class="mx-auto d-flex align-center">Suggested items</div>
        </v-card-title>

        <div class="px-5 pb-3">
          <FriendItemsList :auth-user-id="authUserId" :items="suggestedItems" item-css-class="mb-3" />
        </div>
      </v-card>
    </div>

    <div v-if="!isLoading && secretSantaEvent && receiverUserDisplaySummary">
      <div class="pt-3"></div>

      <v-card>
        <v-card-title v-if="!suggestedItems || suggestedItems.length === 0">No suggested items found</v-card-title>

        <v-card-text>Add a new surprise item</v-card-text>

        <div class="px-4 pb-4">
          <AddItem
            :friend-user-id="receiverUserDisplaySummary.userId"
            :friend-user-name="receiverUserDisplaySummary.fullName"
            :initial-price="budgetAmount"
            @added="onSurpriseItemAdded"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>
